const config = {
    host: (process.env.NODE_ENV === 'production') ? 'https://taxigreen.dvgeo.app' : 'http://192.168.1.106:3024',
    homePage: (process.env.NODE_ENV === 'production') ? 'https://taxigreen.dvgeo.app' : 'http://192.168.1.106:3024',
    api: 'api/',
    apiSocket: 'taxigreen::1.2',
    nameDir: 'taxigreen',
    package: 'app.dvgeo.taxigreen.web',
    version: '1.0.1',
    googleAPIKey: 'AIzaSyCyh19oarVrrS6okvF5X-Ua0ve5brUgWO4',
    appName: 'TaxiGreen',
    provider: 'DEVJOS CIA LTDA',
    colorPrimary: "#00b700",
    colorDark: "#000000",
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'app.dvgeo.taxigreen.passenger',
    playStoreDriverId: 'app.dvgeo.taxigreen.driver',
    appStorePassengerId: '6443663136',
    appStoreDriverId: '6443663091',
    email: "Pauleduardogracia@gmail.com",
};
export default config;
